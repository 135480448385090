import { createStore } from 'redux'

const initialState = {
  logined:  false,
  isMobile: false,
  hasHeader: false,
  pathname: '',
}

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'updateIsMobile':
      return {
        ...state,
        isMobile: action.isMobile
      };
    case 'updatePathname':
      return {
        ...state,
        pathname: action.pathname
      };
    case 'updateHasHeader':
      return {
        ...state,
        hasHeader: action.hasHeader
      };
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
